/* ----- Font ---- */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;800&display=swap');

/* ----- Constants ---- */
$base-color: #0e2d52;
$secondary-color: #efa34c;
$white: #ffffff;
$grey: #333333;
$light-grey: #e5e5e5;
$active-button: #4a617d;

/* ----- Generic ---- */
body {
  background-color: $base-color;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Open Sans', sans-serif;
}

p {
  font-weight: 600;
  margin: auto;
  text-align: center;
}

h1,
h2 {
  margin: auto;
  text-align: center;
}

.page {
  width: 100%;
  min-height: 100%;
}

.logo {
  width: 12em;
  margin: auto;
  display: block;
}

@media only screen and (min-width: 600px) {
  .page {
    min-height: 100vh;
  }

  .logo {
    width: 18em;
  }
}

/* ----- Main Page ---- */
.main-page {
  background-color: $base-color;

  .tagline {
    color: $white;
    max-width: 60%;
    font-size: 2.5em;
    font-weight: 800;
    margin: 1em auto;
  }

  .people {
    width: auto;
    max-width: 100%;
    margin: auto;
    display: block;
  }

  .scroll {
    width: 5em;
    margin: 2em auto;
    display: block;
  }
}

@media only screen and (min-width: 600px) {
  .main-page {
    .tagline {
      font-size: 4em;
    }
  }
}

/* ----- Description Page ---- */
.desc-page {
  background-color: $secondary-color;
  padding: 1em 0;

  .desc-text {
    width: 80%;
    max-width: 1000px;
    margin-top: 10vh;
    font-size: 1.6em;
  }

  .how-we-help {
    margin-top: 12vh;
    font-size: 2em;
  }

  .info-boxes-container {
    width: 80%;
    margin: 10vh auto;
    display: flex;
    flex-wrap: wrap;

    .info-boxes {
      flex: 1;
      min-width: 300px;
      background-color: $white;
      border-radius: 5px;
      margin: 2vh 1%;

      -webkit-box-shadow: 5px 5px 3px -2px rgba(0, 0, 0, 0.75);
      -mox-box-shadow: 5px 5px 3px -2px rgba(0, 0, 0, 0.75);
      box-shadow: 5px 5px 3px -2px rgba(0, 0, 0, 0.75);

      .info-box-icons {
        width: auto;
        margin: 2em auto 0 auto;
        display: block;
      }

      .info-box-text {
        color: $grey;
        padding: 2em;

        font-size: 1.2em;
        line-height: 1.6em;
        letter-spacing: 0.02em;
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .desc-page {
    .desc-text {
      width: 50%;
      max-width: 1000px;
      margin-top: 12vh;
      font-size: 2em;
    }

    .how-we-help {
      font-size: 3.5em;
    }

    .info-boxes-container {
      .info-boxes {
        flex: 1;

        .info-box-icons {
          margin: 2em auto 1em auto;
        }
      }
    }
  }
}

/* ----- SurveyMonkey Page ---- */
.survey-page {
  background-color: $white;
  padding: 10vh 0;

  .survey-box {
    width: 80%;
    max-width: 1000px;
    margin: auto;
    -webkit-box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.75);

    .survey-header {
      max-width: 80%;
      margin: auto;
      padding: 2em 0 0 0;
      font-size: 1.5em;
    }

    .mailchimp-form {
      margin: auto;
      padding: 2em;

      input {
        width: 90%;
        display: block;
        background-color: $light-grey;
        border-radius: 5px;
        border: none;
        padding: 1em 0 1em 1em;
        margin: 1em auto;
        font-size: 1em;
      }

      button {
        width: 15em;
        max-width: 80%;
        margin: 1em auto;
        display: block;
        border-radius: 5px;
        background-color: $base-color;
        color: $white;
        border: none;
        font-size: 1.2em;
        line-height: 3em;
        font-family: 'Open Sans', sans-serif;

        &:hover {
          background: $active-button;
        }

        &:active {
          background: $base-color;
        }
      }
    }
  }

  .social-media {
    .social-media-header {
      max-width: 80%;
      margin: auto;
      padding: 2em 0 0 0;
      font-size: 1.5em;
    }

    .social-media-icons {
      text-align: center;

      .social-media-links {
        color: black;

        .social-media-icon {
          margin: 1em;
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .survey-page {
    .survey-box {
      width: 60%;

      .survey-header {
        font-size: 2em;
      }
    }
  }
}

/* ----- Footer ---- */
.footer {
  background-color: $base-color;
  width: 100%;
}
